// Zipline Modal overrides

.modal-header {

  .close {
    @include border-radius();
    padding: $modal-close-padding-y $modal-close-padding-x;
    font-size: $modal-close-font-size;

    &:focus {
      @include focus-ring();
    }
  }
}

.modal-title  {
  @include font-size($modal-title-font-size);
  font-family: $headings-font-family;
  font-weight: $font-weight-bolder !important; // stylelint-disable-line declaration-no-important
  line-height: $modal-title-line-height;
}
